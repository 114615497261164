import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() imgUrl;
  @Input() isLarge: boolean;
  @Input() isSmall: boolean;
  styleObj: object;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'imgUrl') {
        this.styleObj = {
          'background-image': this.imgUrl ? `url('${this.imgUrl}')` : null
        };
      }
    }
  }
}
