import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActiveStrideService } from '@shared/services/active-stride.service';
import { Chart } from 'chart.js';
import * as annotationPlugin from 'chartjs-plugin-annotation';

export interface CustomDataPoint {
  x: string | number;
  y: string | number;
  stride: number;
}

@Component({
  selector: 'app-bar-hind',
  templateUrl: './bar-hind.component.html',
  styleUrls: ['./bar-hind.component.scss'],
})
export class BarHindComponent implements OnInit, AfterViewInit {
  @Input() dataSet;
  @ViewChild('chart')
  private chartRef: ElementRef;
  private chart: Chart;
  findHigh: any = [];
  findLow: any = [];
  lenght: any;
  padding = 2;
  line = {
    type: 'line',
    mode: 'vertical',
    scaleID: 'x-axis-0',
    value: 0,
    borderColor: 'rgba(248, 148, 6, 1)',
    borderWidth: 2,
  };
  constructor(private strideService: ActiveStrideService) {}

  ngOnInit() {
    const hindSet = this.dataSet.filter((d) => d.body === 'hind');
    const hindLength = hindSet.length;
    const listPos = new Array(hindLength * 2 + this.padding * 4).fill(0);
    const listNeg = new Array(hindLength * 2 + this.padding * 4).fill(0);

    hindSet.map((d: any, index) => {
      const hdMax = d.high_zero[1] - d.high_one[1];
      const hdMin = d.low_zero[1] - d.low_one[1];
      if (Math.sign(hdMax) > 0) {
        // Put left
        listPos[index + this.padding] = {
          x: index + this.padding,
          y: hdMax,
          t: d.stride_id,
        };
      } else {
        // Put right
        listPos[hindLength + index + 6] = {
          x: hindLength + index + 6,
          y: -hdMax,
          t: d.stride_id,
        };
      }

      if (Math.sign(hdMin) > 0) {
        // Put left
        listNeg[index + this.padding] = {
          x: index + this.padding,
          y: -hdMin,
          t: d.stride_id,
        }; // -hdMin;
      } else {
        // Put right
        listNeg[hindLength + index + 6] = {
          x: hindLength + index + 6,
          y: hdMin,
          t: d.stride_id,
        }; // hdMin;
      }
    });

    this.findHigh = listPos;
    this.findLow = listNeg;
    this.lenght = hindLength;
  }

  ngAfterViewInit() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: {
        labels: this.findHigh.map((x, index) => {
          if (index >= this.lenght + 2 * this.padding) {
            return (index - this.lenght - this.padding * 3) % 5 === 0
              ? (index - this.lenght - this.padding * 3).toString()
              : '';
          } else {
            return (index - this.padding) % 5 === 0 ? (index - this.padding).toString() : '';
          }
        }),
        datasets: [
          {
            label: 'High',
            data: this.findHigh,
            backgroundColor: this.createBackgroundColors('high'),
            borderWidth: 2,
            hoverBackgroundColor: 'rgba(248, 148, 6, 1)',
            // fill: false,
          },
          {
            label: 'Low',
            data: this.findLow,
            // borderColor: 'rgba(1, 99, 152, 0.7)',
            borderWidth: 1,
            backgroundColor: this.createBackgroundColors('low'),
            hoverBackgroundColor: 'rgba(248, 148, 6, 1)',
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            text: 'Hind Steps',
            display: true,
          },
          tooltip: { enabled: false },
        },
        hover: { mode: 'nearest', intersect: true /*animationDuration: 100*/ },
        // onHover: (evt, activeElements: any) => {
        //   const elementAtEvent: any = this.chart.getElementAtEvent(evt)[0];
        //   if (activeElements.length) {
        //     const datasetIndex = activeElements[0]._datasetIndex;
        //     const activeDataset: any = this.chart.data.datasets[datasetIndex].data;
        //     this.strideService.updateActiveStride(activeDataset[elementAtEvent._index].t);
        //   }
        // },
        // annotation: {
        //   drawTime: 'afterDraw',
        //   events: ['click', 'touchstart', 'mousemove'],
        //   annotations: [
        //     {
        //       type: 'line',
        //       mode: 'vertical',
        //       scaleID: 'x-axis-0',
        //       value: this.lenght + this.padding * 2,
        //       borderColor: 'rgba(0, 0, 0, 0.15)',
        //       borderWidth: 1,
        //     },
        //     {
        //       type: 'line',
        //       mode: 'horizontal',
        //       scaleID: 'y-axis-0',
        //       value: 0,
        //       borderColor: 'rgba(0, 0, 0, 0.15)',
        //       borderWidth: 1,
        //     },
        //   ],
        // },
        scales: {
          yAxes: {
            ticks: {
              stepSize: 0.5,
            },
            grid: {
              offset: true,
            },
            // gridLines: {
            //   offsetGridLines: true,
            // },
            stacked: false,
            title: {
              text: 'Impact                           Push',
              display: true,
            },
          },
          xAxes: {
            ticks: {
              autoSkip: false,
            },
            grid: {
              offset: true,
              display: false,
            },
            // gridLines: {
            //   offsetGridLines: true,
            //   display: false,
            // },
            stacked: true,
            title: {
              text: 'Left                           Right',
              display: true,
            },
          },
        },
      },
      // plugins: [annotationPlugin],
    });

    // this.chart.register({annotationPlugin})

    this.strideService.activeStride$.subscribe((data: number) => {
      // this.chart.data.datasets.map((dataSet, i) => {
      //   const index: number = dataSet.data.findIndex((d) => d.t === data);
      //   dataSet.backgroundColor = this.createBackgroundColors(
      //     dataSet.label.toLowerCase()
      //   );
      //   if (index >= 0) {
      //     dataSet.backgroundColor[index] = 'rgba(248, 148, 6, 1)';
      //   }
      // });
      // this.chart.update();
    });
  }

  createBackgroundColors(dataSet) {
    const bg = this.findHigh.map(() => {
      if (dataSet === 'high') {
        return 'rgb(1, 99, 132)';
      } else if (dataSet === 'low') {
        return 'rgba(1, 99, 152, 0.7)';
      }
    });
    return bg;
  }
}
