import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '@models/user.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-select-owner',
  templateUrl: './select-owner.component.html',
  styleUrls: ['./select-owner.component.scss']
})
export class SelectOwnerComponent implements OnInit {
  @Input() searchTerm: FormControl;
  @Input() userType: string;
  @Output() owner: EventEmitter<User> = new EventEmitter<User>();

  users$: Observable<User[]>;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.users$ = this.searchTerm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap(term => {
        if (this.userType) {
          return this.userService.searchUserTypeAndTerm(term, this.userType);
        } else {
          return this.userService.searchUserWithTerm(term)
        }
      })
    );
  }

  displayFn(user): string {
    return user && user.name ? `${user.name}` : '';
  }

}
