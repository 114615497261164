import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveStrideService } from '@shared/services/active-stride.service';
import { Chart } from 'chart.js';
import { of } from 'rxjs';
import { distinctUntilChanged, exhaustMap } from 'rxjs/operators';
import { AnalysisActions, AnalysisSelectors, AnalysisStore } from '../../../store/analysis-store';

@Component({
  selector: 'app-fore-plot-chart',
  templateUrl: './fore-plot-chart.component.html',
  styleUrls: ['./fore-plot-chart.component.scss'],
})
export class ForePlotChartComponent implements OnInit, AfterViewInit {
  @Input() dataSet;
  @ViewChild('chart')
  private chartRef: ElementRef;
  private chart: Chart;
  hdMax: any = [];
  hdMin: any = [];

  dataSetIndex: any;
  constructor(private strideService: ActiveStrideService, private store: Store<AnalysisStore.State>) {}

  ngOnInit() {
    // const foreSet = this.dataSet.filter(
    //   (d) => d.body === 'ears' || d.body === 'head'
    // );
  }

  ngAfterViewInit() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: this.createDataSets(),
      options: {
        hover: { mode: 'nearest', intersect: false /*animationDuration: 10*/ },
        onHover: (evt, activeElements: any) => {
          if (!activeElements || !activeElements.length) {
            this.chart.data.datasets.map((dataset, i) => {
              dataset.borderColor = 'rgb(100, 199, 145)';
              dataset.order = i;
              dataset.borderWidth = 2;
            });
            return;
          }
          this.chart.data.datasets.map((dataset, i) => {
            dataset.borderColor = 'rgb(100, 199, 145)';
            dataset.order = i;
          });
          // console.log(activeElements[0]);

          const datasetIndex = activeElements[0]._datasetIndex;
          const activeDataset = this.chart.data.datasets[datasetIndex];
          this.dataSetIndex = datasetIndex;
          /* tslint:disable */
          this.store.dispatch(AnalysisActions.activeStride({ id: (activeDataset as any).strideNo }));
          /* tslint:enable*/
          activeDataset.backgroundColor = 'rgba(248, 148, 6, 1)';
          activeDataset.borderColor = 'rgba(248, 148, 6, 1)';
          activeDataset.borderWidth = 4;
          activeDataset.order = -1;
          this.chart.update();
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Head clock',
          },
          tooltip: { enabled: false },
        },
        scales: {
          xAxes: {
            type: 'linear',
            position: 'bottom',
            min: -4,
            max: 4,
            ticks: {
              display: true,
            },
            title: {
              text: 'Head min',
              display: true,
            },
          },
          yAxes: {
            type: 'linear',
            min: -4,
            max: 4,
            ticks: {},
            title: {
              text: 'Head max',
              display: true,
            },
          },
        },
      },
    });

    this.store
      .select(AnalysisSelectors.selectActiveStride)
      .pipe(
        exhaustMap((data) => of(data)),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe((data) =>
        this.chart.data.datasets.map((dataset) => {
          if ((dataset as any).strideNo === data) {
            dataset.borderColor = 'rgba(248, 148, 6, 1)';
            dataset.order = -1;
            dataset.borderWidth = 4;
          } else {
            dataset.borderColor = 'rgb(100, 199, 145)';
            dataset.borderWidth = 2;
          }

          this.chart.update();
        })
      );
  }

  createDataSets() {
    const strideId = 42;
    const datasets = [];
    this.dataSet.map((d: any, i) => {
      const singleSet = {
        label: 'Hind',
        backgroundColor: d.stride_id === strideId ? 'rgba(218, 141, 0, 0.5)' : 'rgba(100, 199, 145, 0.4)',
        borderColor: d.stride_id === strideId ? 'rgb(218, 141, 0)' : 'rgb(100, 199, 145)',
        hoverBackgroundColor: 'rgba(248, 148, 6, 1)',
        hoverBorderWidth: 4,
        borderCapStyle: 'round',
        fill: false,
        data: this.processData(d),
        pointRadius: 0,
        borderWidth: 2,
        strideNo: d.stride_id,
        order: i,
      };
      datasets.push(singleSet);
    });
    return { datasets };
  }

  processData(data) {
    const hdMax = data.high_zero[1] - data.high_one[1];
    const hdMin = data.low_one[1] - data.low_zero[1];

    return [
      {
        x: 0,
        y: 0,
      },
      {
        x: hdMin,
        y: hdMax,
      },
    ];
  }
}
