import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { documentId, FieldPath } from 'firebase/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HorseService {
  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {}

  getHorseByUid(uid?: string) {
    return this.afs
      .collection('horses', (ref) => ref.where('owner', '==', uid))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getConsumerHorseByUid(uid: string) {
    return this.afs
      .collection('horses', (ref) => ref.where(`roles.${uid}`, 'in', ['horseOwner', 'vet', 'consumer']))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getFirst20Horses() {
    return this.afs
      .collection('/horses', (ref) => ref.where('dbStatus', '==', 'active').limit(20))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getAllHorses() {
    return this.afs
      .collection('/horses', (ref) => ref.where('dbStatus', '==', 'active'))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getHorseByLegacyId(legacyId) {
    return this.afs
      .collection('horses', (ref) => ref.where('__id', '==', legacyId))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getSingleHorse(hId) {
    return this.afs
      .doc(`/horses/${hId}`)
      .snapshotChanges()
      .pipe(
        map((horse: any) => {
          return {
            id: horse.payload.id,
            ...horse.payload.data(),
          };
        })
      );
  }

  getHorsesBasedOnIds(ids) {
    return this.afs
      .collection(`horses`, (ref) => ref.where(documentId(), 'in', ids))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getRecordings(ids) {
    return this.afs.collection(`stride-analysis`);
  }

  searchHorseWithTerm(term) {
    return this.afs
      .collection(`horses`, (ref) =>
        ref
          .where('dbStatus', '==', 'active')
          .orderBy('name')
          .startAt(term)
          .endAt(term + '~')
      )
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((el: any) => ({
            id: el.payload.doc.id,
            ...el.payload.doc.data(),
          }))
        )
      );
  }

  createHorse(data) {
    const toSave = {
      ...data,
      numbers: {
        [data.owner]: {
          numAnalyzing: 0,
          numEvents: 0,
          numNewAnalyzedRecordings: 0,
          numPending: 0,
          numRecordings: 0,
          numUpdatedAnalysis: 0,
        },
      },
    };
    return this.afs.collection('horses').add(toSave);
  }
}
