
export const environment = {
  production: true,
  hmr: false,
  env: 'germany',
  firebase: {
    apiKey: "AIzaSyDXrzbzc5z13zApfpjrC-iB2fQSjC16f74",
    authDomain: "sleip-german-federation.firebaseapp.com",
    messagingSenderId: "425188224743",
    projectId: "sleip-german-federation",
    appId: "1:425188224743:web:625ba7687db2f53f36bc42",
    storageBucket: "sleip-german-federation.appspot.com",
    measurementId: "G-22FLTY35YQ"
  },
};


