import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ElementRef,
  OnInit,
  Renderer2,
  OnChanges,
} from "@angular/core";
@Component({
  selector: "app-svg-icon",
  template: ` <ng-template></ng-template> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() material = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.loadSvg();
  }

  ngOnChanges() {
    this.loadSvg();
  }

  loadSvg() {
    try {
      const svgStr = this.material
        ? require(`!svg-inline-loader!../../../../svg/${this.name}-24px.svg`)
        : require(`!svg-inline-loader!../../../../svg/${this.name}.svg`);
      const element = this.el.nativeElement;
      this.renderer.setProperty(this.el.nativeElement, "innerHTML", svgStr);
      const classList = element.className.split(" ");
      for (const className of classList) {
        this.renderer.addClass(element.firstElementChild, className);
      }
    } catch (error) {
      // console.error(error.message, this.name);
    }
  }
}
