import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { AnalysisResultsService } from '../../../shared/services/analysis-results/analysis-results.service';
import { StrideService } from '../../../shared/services/stride.service';
import * as AnalysisActions from '../actions/analysis.actions';

@Injectable()
export class AnalysisEffects {
  loadAnalysiss$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalysisActions.loadAnalysiss),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map((data) => AnalysisActions.loadAnalysissSuccess({ data })),
          catchError((error) => of(AnalysisActions.loadAnalysissFailure({ error })))
        )
      )
    );
  });

  loadSingleAnalyis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AnalysisActions.loadAnalysisById),
      mergeMap(({ id }) => {
        return this.analysisResultsService.loadSingleAnalysisById(id).pipe(
          map((analysis) => {
            return AnalysisActions.addSingleAnalysis({ analysis });
          })
        );
      }),
      catchError((err) => {
        console.log(err);
        return EMPTY;
      })
    );
  });

  constructor(private actions$: Actions, private analysisResultsService: AnalysisResultsService) { }
}
