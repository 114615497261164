<!-- <mat-form-field appearance="fill">
  <mat-label>Start Date</mat-label>
  <input matInput [matDatepicker]="picker">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field> -->

<pre>
  {{ dateForm.value | json }}
</pre>
