import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { HorseActions } from '../';
import { HorseService } from '../../../shared/services/horse.service';
import * as HorsesActions from '../actions/horses.actions';

@Injectable()
export class HorsesEffects {
  loadHorses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorsesActions.loadHorses),
      switchMap(({ uid }) => {
        const ownerStream = this.horseService.getHorseByUid(uid);
        // const consumerStream = this.horseService.getConsumerHorseByUid(uid);
        // combineLatest(ownerStream, consumerStream).pipe(  );
        return ownerStream.pipe(map(horses => {
          return HorsesActions.addHorses({ horses });
        })
        );
      })
    );
  });

  loadConsumerHorses = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadConsumerHorses),
      switchMap(({ uid }) => {
        const consumerStream = this.horseService.getConsumerHorseByUid(uid);
        return consumerStream.pipe(
          map(horses => {
            return HorseActions.addHorses({ horses });
          })
        );
      })
    );
  });

  loadSingleHorseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorseActions.loadSinglehorse),
      mergeMap(({ id }) => {
        const singleHorse = this.horseService.getSingleHorse(id);
        return singleHorse.pipe(
          map(horse => {
            return HorseActions.addSingleHorse({ horse });
          })
        );
      })
    );
  });

  loadAdminHorses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HorsesActions.loadAdminHorses),
      exhaustMap(() => {
        return this.horseService.getAllHorses().pipe(
          map(horses => {
            return HorsesActions.addHorses({ horses });
          })
        );
      })
    );
  });

  constructor(private actions$: Actions, private horseService: HorseService) { }
}
