import { User } from '@models/user.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  uid: string;
  isAuthenticated: boolean;
  user: User;
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

export const initialState: State = {
  uid: null,
  isAuthenticated: null,
  user: null,
  loading: false,
  loaded: false,
  error: null
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.loadAuths, (state) => state),
  on(AuthActions.loadUser, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(AuthActions.authenticated, (state, { user }) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      isAuthenticated: true,
      uid: user.id,
      user
    };
  }),
  on(AuthActions.notAuthenticated, (state) => {
    return {
      ...state,
      isAuthenticated: false,
      loading: false,
      loaded: true
    };
  }),
  on(AuthActions.loginUser, (state, { email, password }) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(AuthActions.loadAuthsSuccess, (state, action) => state),
  on(AuthActions.loadAuthsFailure, (state, action) => state)
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
