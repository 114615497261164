import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActiveStrideService } from '@shared/services/active-stride.service';
// import videojs from 'video.js';

@Component({
  selector: 'app-video',
  template: ` <video #target class="video-js" controls muted playsinline preload="none"></video> `,
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  @Input() options: {
    fluid: boolean;
    autoplay: boolean;
    techOrder?: ['html5'];
    sources: {
      src: string;
      type: string;
    }[];
  };
  // player: videojs.Player;
  constructor(private elementRef: ElementRef, private activeStrideService: ActiveStrideService) {}

  ngOnInit() {
    // this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {});
    // this.player.on('timeupdate', (a) => {
    //   const currentTime = this.player.currentTime();
    //   const duration = this.player.duration();
    //   const numOfFrames = duration * 60;
    //   const currentFrame = currentTime * 60;
    //   this.activeStrideService.updateVideoStrides(Math.floor(currentFrame));
    // });
    // this.player.on('play', (a) => {
    //   this.activeStrideService.updateVideoPlaying(true);
    // });
    // this.player.on('pause', (a) => {
    //   this.activeStrideService.updateVideoPlaying(false);
    // });
  }

  updateValue(a: any) {
    // console.log('update value', a);
  }

  ngOnDestroy() {
    // destroy player
    // if (this.player) {
    //   this.player.dispose();
    // }
  }
}
