<h3>
  Login
</h3>

<div class="form" [formGroup]="loginForm">
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        placeholder="Email"
      />
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        type="password"
        formControlName="password"
        placeholder="Password"
      />
    </mat-form-field>
  </p>
  <button class="button" (click)="login()" type="submit">
    GO!
  </button>
</div>
