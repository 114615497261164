import { createAction, props } from '@ngrx/store';

export const loadUser = createAction('[AUTH / STORE] = Get user from firestore');

export const loginUser = createAction(
  '[AUTH / API] - Try login user',
  props<{ email: string; password: string }>()
);

export const loginFailed = createAction('[AUTH / API] - Login failed', props<{ error: any }>());

export const signUpUser = createAction(
  '[AUTH / API] - Singup user',
  props<{ email: any; password: string }>()
);

export const logoutUser = createAction('[AUTH / API] - Logout user');

export const authenticated = createAction('[AUTH / STORE] - Authenticated', props<{ user: any }>());

export const notAuthenticated = createAction('[AUTH / STORE] -  Not Authenticated');

export const authError = createAction('[AUTH / STORE] - Auth Error');

export const loadAuths = createAction('[Auth] Load Auths');

export const passwordReset = createAction('[AUTH / API ] - passwordReset');

export const loadAuthsSuccess = createAction('[Auth] Load Auths Success', props<{ data: any }>());

export const loadAuthsFailure = createAction('[Auth] Load Auths Failure', props<{ error: any }>());
