import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveStrideService } from '@shared/services/active-stride.service';
import { Chart, ChartOptions, LinearScale, LineController, LineElement, Point } from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { Observable, of, timer } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { AnalysisActions, AnalysisSelectors, AnalysisStore } from '../../../store/analysis-store';

// TODO: MAKE SURE TO UPDATE THE .frame to frame_no
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit {
  @Input() stride: Observable<any>;
  @Input() dataSet;
  @Input() listOfStrides: any;
  @ViewChild('chart')
  private chartRef: ElementRef;
  private chart: Chart;
  private data: Point[];
  private frame: any = 1000;
  isPlaying = false;
  activeVideoFrame = 0;

  verticalLinePlugin = {
    getLinePosition(chart, pointIndex) {
      const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
      const data = meta.data;
      return data[pointIndex]._model.x;
    },
    renderVerticalLine(chartInstance, pointIndex) {
      const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
      const scale = chartInstance.scales['y-axis-0'];
      const context = chartInstance.chart.ctx;

      // render vertical line
      context.beginPath();
      context.strokeStyle = '#ff0000';
      context.moveTo(lineLeftOffset, scale.top);
      context.lineTo(lineLeftOffset, scale.bottom);
      context.stroke();
    },

    afterDatasetsDraw(chart, easing) {
      if (chart.config.lineAtIndex) {
        chart.config.lineAtIndex.forEach((pointIndex) => this.renderVerticalLine(chart, pointIndex));
      }
    },
  };
  constructor(private strideService: ActiveStrideService, private store: Store<AnalysisStore.State>) {}

  ngAfterViewInit() {
    // Register chart anotation.
    Chart.register(ChartAnnotation, LinearScale, LineController, LineElement);
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Hind',
            backgroundColor: 'rgba(123, 22, 132,0.4)',
            borderColor: 'rgb(1, 99, 132)',
            fill: false,
            data: this.createDataSet().hind,
            pointRadius: 0,
            borderWidth: 1,
          },
          {
            label: 'Head',
            backgroundColor: 'rgb(100, 199, 145, 0.4)',
            borderColor: 'rgb(100, 199, 145)',
            fill: false,
            data: this.createDataSet().head,
            pointRadius: 0,
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false,
            text: 'Graphs',
          },
          tooltip: { enabled: false },
        },
        hover: { mode: 'nearest', intersect: true /*animationDuration: 100 */ },
        // annotation: {
        //   drawTime: 'afterDraw',
        //   events: ['click', 'touchstart', 'mousemove'],
        //   annotations: this.createAnotations(),
        // },
        scales: {
          xAxes: {
            type: 'linear',
            position: 'bottom',
            ticks: {
              display: false,
            },
            title: {
              text: 'Frame',
              display: false,
            },
          },
          yAxes: {
            type: 'linear',
            max: 1,
            min: -1,
            ticks: {
              stepSize: 0.5,
            },
            title: {
              text: 'Stride',
              display: true,
            },
          },
        },
      },
      // plugins: [ChartAnnotation],
    });

    // this.updateStrideAnotation();
    this.strideService.playingVideo$.subscribe((data) => (this.isPlaying = data));
    this.onMoviePlay();
    this.updateStrideAnotation();
  }

  updateStrideAnotation() {
    this.store
      .select(AnalysisSelectors.selectActiveStride)
      .pipe(exhaustMap((data) => of(data)))
      .subscribe((data: number) => {
        const chartHolder: any = this.chart;
        chartHolder.annotation.elements = [];
        // this.chart.options.annotation.annotations = this.createAnotations(data);
        this.chart.update();
      });
    // this.strideService.activeStride$.subscribe((data: number) => {

    // });
  }

  onMoviePlay() {
    this.strideService.videoStride$.subscribe((data) => {
      if (this.isPlaying) {
        // this.activeVideoFrame = data;
        // const anotation: any = this.chart.options.annotation.annotations[0];
        // anotation.value = data;
        this.chart.update();
      }
    });
  }

  createDataSet() {
    console.log(this.dataSet);
    const headConst = this.dataSet.filter((b) => {
      if (b.head) {
        return b.head;
      }
    });
    const singleHead = headConst.map((a) => {
      return a.head;
    });
    const headMin = Math.min(...singleHead);
    const headMax = Math.max(...singleHead);
    const multiplier = (headMax - headMin) * 1.25;

    const earsConst = this.dataSet.filter((b) => {
      if (b.ears) {
        return b.ears;
      }
    });
    const singleEars = earsConst.map((a) => {
      return a.ears;
    });
    const earsMin = Math.min(...singleEars);
    const earsMax = Math.max(...singleEars);
    const earsMultiplier = (earsMax - earsMin) * 1.25;

    const hindConst = this.dataSet.filter((b) => {
      if (b.hind) {
        return b.hind;
      }
    });

    const singleHind = hindConst.map((a) => {
      return a.hind;
    });

    const hindMin = Math.min(...singleHind);
    const hindMax = Math.max(...singleHind);
    const hindMultiplier = (hindMax - hindMin) * 1.25;

    const head = this.dataSet.map((d) => {
      return {
        x: d.frame_no,
        y:
          d.head !== false
            ? (d.head - headMin) / multiplier + 0.1
            : d.ears !== false
            ? (d.ears - earsMin) / earsMultiplier + 0.1
            : null,
        steppedLine: d.head === false ? true : false,
      };
    });

    const hind = this.dataSet.map((d) => {
      return {
        x: d.frame_no,
        y: d.hind !== false ? (d.hind - hindMax) / hindMultiplier - 0.1 : null,
        steppedLine: d.hind === false ? true : false,
      };
    });

    const ears = this.dataSet.map((d) => {
      return {
        x: d.frame_no,
        y: d.ears !== false ? d.ears : null,
        steppedLine: d.ears === false ? true : false,
      };
    });

    const data = {
      head,
      hind,
      ears,
    };

    return data;
  }

  getInfo(n) {
    // if (!this.isPlaying) {
    const chartHolder: any = this.chart;
    chartHolder.annotation.elements = [];
    // this.chart.options.annotation.annotations = this.createAnotations(n);
    this.chart.update();
    this.store.dispatch(AnalysisActions.activeStride({ id: n }));
    // this.strideService.updateActiveStride(n);
    // }
  }

  createAnotations(n?: number) {
    const anotations = [];
    anotations.push({
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-0',
      value: this.activeVideoFrame,
      borderColor: 'rgba(248, 148, 6, 1)',
      borderWidth: 2,
    });
    this.listOfStrides.map((stride, i) => {
      const odd = i % 2;
      let bgColor;
      if (n && n === i) {
        bgColor = 'rgba(248, 148, 6, 0.1)';
      } else if (stride.body === 'hind') {
        bgColor = 'rgba(52, 152, 219, 0.05)';
      } else {
        bgColor = 'rgba(78, 205, 196, 0.05)';
      }
      const anotationBox = {
        type: 'box',
        id: stride.stride_id.toString(),
        xScaleID: 'x-axis-0',
        yScaleID: 'y-axis-0',
        xMin: stride.start,
        xMax: stride.end,
        yMin: stride.body === 'hind' ? -0 : 0,
        yMax: stride.body === 'hind' ? -1 : 1,
        backgroundColor: bgColor,
        borderWidth: 0,
        borderColor: bgColor,
        onMouseover: () => {
          this.getInfo(i);
        },
      };
      anotations.push(anotationBox);
    });

    return anotations;
  }
}
