import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmailSignupService } from '../services/email-signup.service';

@Directive({
  selector: '[appEmailTaken]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailTakenDirective, multi: true }
  ]
})
export class EmailTakenDirective {
  static createValidator(signupService: EmailSignupService) {
    return (control: AbstractControl) => {
      return signupService.getEmails(control.value).pipe(
        map((res) => {
          return res ? null : { emailTaken: true };
        })
      );
    };
  }
}
