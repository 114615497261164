<div class="owner-input-wrapper">
  <div class="form-field">
    <div class="form-field__control">
      <input
        type="text"
        class="form-field__input"
        placeholder=" "
        name="label"
        id="label"
        [matAutocomplete]="auto"
        [formControl]="control"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <!-- Preperation for loading state -->
        <!-- <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!isLoading"> -->
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
          <span class="select-item">
            <b>{{ option.name }}</b></span
          >
        </mat-option>
        <!-- </ng-container> -->
      </mat-autocomplete>
      <label for="label" class="form-field__label">{{ label }}</label>
    </div>
  </div>
</div>
