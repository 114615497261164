import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOnFocus]',
})
export class FocusDirective {
  private el: ElementRef;
  constructor(private _el: ElementRef, public renderer: Renderer2) {
    this.el = this._el;
  }

  @HostListener('focus', ['$event']) onFocus(e: any) {
    this.renderer.addClass(this._el.nativeElement.parentNode, 'focused');
    return;
  }
  @HostListener('blur', ['$event']) onblur(e: any) {
    this.renderer.removeClass(this._el.nativeElement.parentNode, 'focused');
    return;
  }
}
