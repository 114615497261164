import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveStrideService } from '@shared/services/active-stride.service';
import { Chart } from 'chart.js';
import { type } from 'os';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, exhaustMap } from 'rxjs/operators';
import { AnalysisActions, AnalysisSelectors, AnalysisStore } from '../../../store/analysis-store';

@Component({
  selector: 'app-chart-head-hind',
  templateUrl: './chart-head-hind.component.html',
  styleUrls: ['./chart-head-hind.component.scss'],
})
export class ChartHeadHindComponent implements AfterViewInit {
  @Input() strides: Observable<any>;
  @Input() dataSet;
  @Input() head: boolean;
  @ViewChild('chart')
  private chartRef: ElementRef;
  private chart: Chart;
  constructor(private strideService: ActiveStrideService, private store: Store<AnalysisStore.State>) {}

  dataSetIndex: any;

  ngAfterViewInit() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: this.createDataset(),
      options: {
        hover: { mode: 'nearest', intersect: false },
        onHover: (evt, activeElements: any) => {
          if (!activeElements || !activeElements.length) {
            this.chart.data.datasets.map((dataset, i) => {
              dataset.borderColor = this.head ? 'rgb(100, 199, 145)' : 'rgb(1, 99, 132)';
              dataset.order = i;
              dataset.borderWidth = 2;
            });
            return;
          }
          this.chart.data.datasets.map((dataset, i) => {
            dataset.borderColor = this.head ? 'rgb(100, 199, 145)' : 'rgb(1, 99, 132)';
            dataset.order = i;
          });
          // console.log(activeElements[0]);

          const datasetIndex = activeElements[0]._datasetIndex;
          const activeDataset = this.chart.data.datasets[datasetIndex];
          this.dataSetIndex = datasetIndex;
          /* tslint:disable */
          this.store.dispatch(AnalysisActions.activeStride({ id: (activeDataset as any).strideNo }));
          // this.strideService.updateActiveStride(activeDataset.strideNo);
          /* tslint:enable*/
          activeDataset.backgroundColor = 'rgba(248, 148, 6, 1)';
          activeDataset.borderColor = 'rgba(248, 148, 6, 1)';
          activeDataset.borderWidth = 4;
          activeDataset.order = -1;
          this.chart.update();
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: this.head ? 'Head Motion ' : 'Hind Motion',
          },
          tooltip: { enabled: false },
        },

        // find documentation how to remove the plottery thing -0.1 - 1.1
        scales: {
          xAxes: {
            type: 'linear',
            position: 'bottom',
            min: -0.05,
            max: 1.05,
            suggestedMin: 0,
            suggestedMax: 1,
            ticks: {
              display: true,
              stepSize: 0.5,
            },
            title: {
              text: 'Left                           Right',
              display: true,
            },
          },
          yAxes: {
            type: 'linear',
            ticks: {
              stepSize: 0.5,
            },
            title: {
              text: 'Percent of Horse Height',
              display: true,
            },
          },
        },
      },
    });

    this.store
      .select(AnalysisSelectors.selectActiveStride)
      .pipe(
        exhaustMap((data) => of(data)),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe((data: number) => {
        this.chart.data.datasets.map((dataset, i) => {
          if ((dataset as any).strideNo === data) {
            dataset.borderColor = 'rgba(248, 148, 6, 1)';
            dataset.order = -1;
            dataset.borderWidth = 4;
          } else {
            dataset.borderColor = this.head ? 'rgb(100, 199, 145)' : 'rgb(1, 99, 132)';
            dataset.borderWidth = 2;
            dataset.order = i;
          }

          this.chart.update();
        });
      });
  }

  createDataset() {
    const datasets = [];
    this.dataSet.map((data, i) => {
      const singleSet = {
        label: 'Hind',
        backgroundColor: 'rgba(123, 22, 132,0.4)',
        borderColor: this.head ? 'rgb(100, 199, 145)' : 'rgb(1, 99, 132)',
        hoverBackgroundColor: 'rgba(248, 148, 6, 1)',
        hoverBorderWidth: 0,
        fill: false,
        data: this.processData(data),
        pointRadius: 0,
        borderWidth: 2,
        strideNo: data[0].strideId,
        order: i,
      };
      datasets.push(singleSet);
    });

    return { datasets };
  }

  processData(data) {
    let a = 0;
    const dataLength = data.length;
    const xStepSize: number = 1 / (dataLength - 1);

    return data.map((d) => {
      if (this.head) {
        const dataStep = {
          x: a,
          y: d.head !== false ? d.head : d.ears !== false ? d.ears : null,
          steppedLine: d.head === false ? true : false,
        };
        a = a + xStepSize;
        return dataStep;
      } else {
        const hind = {
          x: a,
          y: d.hind !== false ? d.hind : null,
          steppedLine: d.hind === false ? true : false,
        };
        a = a + xStepSize;
        return hind;
      }
    });
  }
}
