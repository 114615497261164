import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://01a279a806df4ad4a116f35f17f4287a@o615979.ingest.sentry.io/5750105',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'https://sleip.ai'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap().catch((err: any) => console.log(err));
    // function bootstrap() {
    //   bootstrap();
    // }

    // if (document.readyState === 'complete') {
    //   bootstrap();
    // } else {
    //   document.addEventListener('DOMContentLoaded', bootstrap);
    // }
  });
}
