import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { User } from '@sentry/types';
import { EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { UserService } from '../../../shared/services/user.service';
import * as UserActions from '../actions/user.actions';

@Injectable()
export class UserEffects {

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap(() => {
        const userStream = this.userService.getAllUsers();

        return userStream.pipe(
          map(users => {
            return UserActions.loadUsersSuccess({ users });
          })
        );
      })
      /*
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request
        EMPTY.pipe(
          map(data => UserActions.loadUsersSuccess({ data })),
          catchError(error => of(UserActions.loadUsersFailure({ error }))))
      )
      */
    );
  });

  loadSingleUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadSingleUser),
      switchMap(({ uid }) => {
        console.log('this is uid', uid);
        const userStream = this.userService.getUser(uid);
        return userStream.pipe(
          map((user) => {
            console.log('we have a user', user);
            return UserActions.addSingleUser({ user });
          })
        );
      })
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap(({ user }) => {
        return of(this.userService.updateUser(user));
      }),
      catchError(err => of(UserActions.failedUpdateUser({ err }))),
      map(d => {
        return UserActions.successUpdateUser();
      }
      )
    );
  });



  constructor(private actions$: Actions, private userService: UserService) { }

}
