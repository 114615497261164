import { Horse } from '@models/horse.model';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, horsesFeatureKey, State } from '../reducers/horses.reducer';

export const selectHorsesState: MemoizedSelector<object, State> = createFeatureSelector<State>(
  horsesFeatureKey
);

export const isLoading = (state: State): boolean => state.loading;
export const isLoaded = (state: State): boolean => state.loaded;


export const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(selectHorsesState, isLoading);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(selectHorsesState, isLoaded);


export const selectAllHorses: (state: object) => any[] = adapter.getSelectors(selectHorsesState).selectAll;

export const selectHorseWithOwner = (owner: string) => createSelector(selectAllHorses, (horses: any[]) => {
  if (horses) {
    return horses.filter(horse => horse.owner === owner);
  } else {
    return null;
  }
});

export const selectHorsesAssociatedWithUser = (uid: string) => createSelector(selectAllHorses, (horses: Horse[]) => {
  if (horses) {
    return horses.filter((obj: Horse) => {
      const rolesUser = Object.keys(obj.roles);
      const roleIncludesUser = rolesUser.includes(uid);
      if (roleIncludesUser) {
        return obj;
      }
    });
  } else {
    return null;
  }
});


export const selectSingleHorse = (id: string) => createSelector(selectAllHorses, (horses: Horse[]) => {
  if (horses) {
    return horses.find(horse => horse.id === id);
  } else {
    return null;
  }
});
