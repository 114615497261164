<div class="user-input-wrapper">
  <label for="horse"> Horse name</label>
  <input
    type="text"
    class="form-group"
    placeholder="Search by horse name"
    [matAutocomplete]="auto"
    [formControl]="searchTerm"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <!-- Preperation for loading state -->
    <!-- <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading"> -->
    <mat-option *ngFor="let horse of horses$ | async" [value]="horse">
      <span class="select-item">
        <img [src]="horse.image" alt="" /><b>{{ horse.name }}</b></span
      >
    </mat-option>
    <!-- </ng-container> -->
  </mat-autocomplete>
</div>
