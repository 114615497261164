import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { User } from '../../../../models/user.model';

interface QueryConfig {
  path: string;
  field: string;
  limit?: number;
  reverse?: boolean;
  prepend?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // private _done = new BehaviorSubject(false);
  // private _loading = new BehaviorSubject(false);
  // private _data = new BehaviorSubject([]);

  // data: Observable<any>;
  // done: Observable<boolean> = this._done.asObservable();
  // loading: Observable<boolean> = this._loading.asObservable();

  constructor(private afs: AngularFirestore, private fns: AngularFireFunctions) { }

  getUser(id): Observable<User> {
    return this.afs
      .doc(`users/${id}`)
      .snapshotChanges()
      .pipe(
        distinctUntilChanged(),
        map((snap: any) => {
          return {
            uid: snap.payload.id,
            ...snap.payload.data(),
          };
        })
      );
  }

  getUsersByIds(ids: string[]): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('uid', 'in', ids))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map(
            (user: any) =>
            ({
              id: user.payload.doc.id,
              ...user.payload.doc.data(),
            } as User[])
          );
        })
      );
  }

  getUsersByEmail(email: string[]): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('email', 'in', email))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map(
            (user: any) =>
            ({
              id: user.payload.doc.id,
              ...user.payload.doc.data(),
            } as User[])
          );
        })
      );
  }

  getAllUsers() {
    return this.afs
      .collection('users')
      .snapshotChanges()
      .pipe(
        distinctUntilChanged(),
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const uid = a.payload.doc.id;
            return { uid, ...data };
          })
        )
      );
  }

  createUser(data) {
    const { password, ...userData } = data;
    // Moved creating user to the server.
    const callable = this.fns.httpsCallable('userApi-createUser');
    return callable(data);
  }

  searchUserWithTerm(term) {
    return this.afs
      .collection(`users`, (ref) =>
        ref
          .orderBy('name')
          .startAt(term)
          .endAt(term + '~')
      )
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((el: any) => ({
            id: el.payload.doc.id,
            ...el.payload.doc.data(),
          }))
        )
      );
  }

  searchUserTypeAndTerm(term, type) {
    return this.afs
      .collection(`users`, (ref) =>
        ref
          .orderBy('name')
          .startAt(term)
          .endAt(term + '~')
          .where('appAccess', 'in', type)
      )
      .snapshotChanges()
      .pipe(
        map((snap) =>
          snap.map((el: any) => ({
            id: el.payload.doc.id,
            ...el.payload.doc.data(),
          }))
        )
      );
  }

  updateUser(user: User) {
    console.log(user);
    return this.afs.doc(`users/${user.uid}`).update(user);
  }

  getAdminUsers(): Observable<User[]> {
    return this.afs
      .collection(`users`, (ref) => ref.where('admin', '==', true))
      .snapshotChanges()
      .pipe(
        map((snap: any) => {
          return snap.map(
            (user: any) =>
            ({
              id: user.payload.doc.id,
              ...user.payload.doc.data(),
            } as User[])
          );
        })
      );
  }
}
