import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/material/material.module';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BarHindComponent } from './components/bar-hind/bar-hind.component';
import { ButtonComponent } from './components/button/button.component';
import { ChartHeadHindComponent } from './components/chart-head-hind/chart-head-hind.component';
import { ChartComponent } from './components/chart/chart.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ForePlotChartComponent } from './components/fore-plot-chart/fore-plot-chart.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormTextAreaComponent } from './components/form-text-area/form-text-area.component';
import { SelectHorseComponent } from './components/select-horse/select-horse.component';
import { SelectOwnerComponent } from './components/select-owner/select-owner.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { UploaderTaskComponent } from './components/uploader-task/uploader-task.component';
import { VideoComponent } from './components/video/video.component';
import { EmailTakenDirective } from './directives/email-taken.directive';
import { FocusDirective } from './directives/focus.directive';
import { FocusFragmentDirective } from './directives/focusFragment/focus-fragment.directive';
import { ScrollableDirective } from './directives/scrollable.directive';
import { FormSelectComponent } from './components/form-select/form-select.component';

@NgModule({
    declarations: [
        SvgIconComponent,
        EmailTakenDirective,
        DialogComponent,
        AvatarComponent,
        ButtonComponent,
        FormFieldComponent,
        FocusDirective,
        FormTextAreaComponent,
        UploaderTaskComponent,
        ChartComponent,
        VideoComponent,
        ChartHeadHindComponent,
        BarHindComponent,
        ForePlotChartComponent,
        SelectHorseComponent,
        ScrollableDirective,
        FocusFragmentDirective,
        SelectOwnerComponent,
        DatePickerComponent,
        FormSelectComponent,
    ],
    imports: [CommonModule, MaterialModule, RouterModule, ReactiveFormsModule, FormsModule, HttpClientModule],
    exports: [
        SvgIconComponent,
        EmailTakenDirective,
        DialogComponent,
        AvatarComponent,
        ButtonComponent,
        FormFieldComponent,
        FocusDirective,
        FormTextAreaComponent,
        UploaderTaskComponent,
        ChartComponent,
        VideoComponent,
        ChartHeadHindComponent,
        BarHindComponent,
        ForePlotChartComponent,
        SelectHorseComponent,
        ScrollableDirective,
        FocusFragmentDirective,
        SelectOwnerComponent,
        DatePickerComponent,
        FormSelectComponent,
    ]
})
export class SharedModule {}
