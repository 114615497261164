import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateFormat } from '../../../../../models/date.model';

@Component({
  selector: 'app-datepicker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  currentDate = new Date();
  @Output() dates: DateFormat;
  dateForm: FormGroup;
  startDate: any = new Date();
  endDate: any = new Date();

  constructor(private fb: FormBuilder) {
    this.startDate.setMonth(this.endDate.getMonth() - 3, 2);
    this.startDate.setHours(0, 0, 0, 0);
    this.dateForm = this.fb.group({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }

  ngOnInit(): void {
  }

}
