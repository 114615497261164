/**
 * directive that causes the browser to scroll and focus to the anchor from the latest activated route fragment
 * usage:
 * link with fragment: <a tabindex="0" [routerLink]="['/']" fragment="contact">
 * html in / route page:
 * <section appFocusFragment>
 *  <a id="contact">First focused content after load</a>
 */
import { AfterViewInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[appFocusFragment]',
})
export class FocusFragmentDirective implements AfterViewInit {
  private fragment: string = '';
  constructor(private route: ActivatedRoute) {
    // be sure the navigation goes to the # fragment
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.fragment = fragment;
      }
    });
  }

  ngAfterViewInit(): void {
    try {
      const anchor = document.querySelector('#' + this.fragment);
      setTimeout(() => {
        if (anchor) {
          anchor.scrollIntoView();
        }
      }, 250);
    } catch (e) {}
  }
}
