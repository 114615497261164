import { AfterViewInit, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @ViewChild('label') label: ElementRef;

  constructor(private _el: ElementRef) { }

  focus() {
    this._el.nativeElement.querySelector('input').focus();
    this._el.nativeElement.querySelector('label').class = 'focused';
  }

  ngAfterViewInit() {
  }


}
