import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-form-text-area',
  templateUrl: './form-text-area.component.html',
  styleUrls: ['./form-text-area.component.scss']
})
export class FormTextAreaComponent {
  constructor(private _el: ElementRef) {}

  focus() {
    this._el.nativeElement.querySelector('textarea').focus();
  }
}
