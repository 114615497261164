import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalysisResultsService {

  constructor(
    private afs: AngularFirestore
  ) { }

  loadSingleAnalysisById(id) {
    return this.afs.doc(`analysisResults/${id}`)
      .snapshotChanges()
      .pipe(
        map((rec: any) => {
          return {
            id: rec.payload.id,
            ...rec.payload.data(),
          };
        })
      );
  }
}
