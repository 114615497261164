import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Horse } from '@models/horse.model';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, mergeMap, skipWhile } from 'rxjs/operators';
import { HorseService } from '../../services/horse.service';

@Component({
  selector: 'app-select-horse',
  templateUrl: './select-horse.component.html',
  styleUrls: ['./select-horse.component.scss']
})
export class SelectHorseComponent implements OnInit {
  @Input() searchTerm: FormControl;
  @Output() horse: EventEmitter<Horse> = new EventEmitter<Horse>();

  horses$: Observable<any>;

  constructor(private horseService: HorseService) { }


  ngOnInit() {
    this.horses$ = this.searchTerm.valueChanges.pipe(
      debounceTime(300),
      // filter(res => res.length > 2),
      // skipWhile(res => res && res.length < 2),
      distinctUntilChanged(),
      mergeMap(term => this.horseService.searchHorseWithTerm(term))
    );
  }

  displayFn(horse): string {
    return horse && horse.name ? `${horse.name}` : '';
  }
}
