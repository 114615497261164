import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<any>;
  constructor(public afAuth: AngularFireAuth, private router: Router) {
    this.user = this.afAuth.authState;
  }

  async login(credentials: any) {
    return await this.afAuth
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((cred) => {
        return true;
      })
      .catch((err: any) => {
        return false;
      });
  }
}
