import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveStrideService {
  activeStride$: Observable<number>;
  videoStride$: Observable<number>;
  playingVideo$: Observable<boolean>;
  private activeStrideSub = new Subject<number>();
  private videoStrideSub = new Subject<number>();
  private videoPlayingSub = new Subject<boolean>();
  constructor() {
    this.activeStride$ = this.activeStrideSub.asObservable();
    this.videoStride$ = this.videoStrideSub.asObservable();
    this.playingVideo$ = this.videoPlayingSub.asObservable();
  }

  updateActiveStride(data: number) {
    this.activeStrideSub.next(data);
  }

  updateVideoStrides(data: number) {
    this.videoStrideSub.next(data);
  }

  updateVideoPlaying(data: boolean) {
    this.videoPlayingSub.next(data);
  }
}
