import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-uploader-task',
  templateUrl: './uploader-task.component.html',
  styleUrls: ['./uploader-task.component.scss'],
})
export class UploaderTaskComponent implements OnInit {
  @Input() file: File;
  @Input() horse: string;
  @Input() id: string;
  @Output() uploadComplete: EventEmitter<string> = new EventEmitter();

  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {}

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    // The storage path
    const path = `video/${this.horse}_${new Date().toISOString()}_${this.id}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // Meta data
    const metadata = {
      customMetadata: {
        path: 'recordings',
        objectPath: this.id,
      },
    };

    // The main task
    this.task = this.storage.upload(path, this.file, metadata);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        // this.db
        //   .doc(`recordings/${this.id}`)
        //   .update({ videofiles: { original: this.downloadURL, arrowMov: null, compressed: null } });

        this.uploadComplete.emit(this.downloadURL);
      })
    );
  }

  isActive(snapshot: any) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }
}
