import { Component, OnInit, Inject } from '@angular/core';
import { EmailSignupService } from './shared/services/email-signup.service';
import {
  FormControl,
  Validators,
  AbstractControl,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { map, debounceTime, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
// import {
//   MatDialog,
//   MatDialogRef,
//   MAT_DIALOG_DATA
// } from '@angular/material/dialog';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {}
